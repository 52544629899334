$white: white;
$black: #000000;
$grey-1: #42517a;
$grey-2: #304054;
$grey-3: #5b697b;
$grey-4: #8c97a5;
$grey-5: #bfc5cc;
$grey-6: #dee4eb;
$grey-7: #ecf0f4;
$grey-8: #fbfcfe;
$accent: #f84069;
$accent-l: #faf3ef;

.root1 {
  --white: #ffffff;
  --black: #000000;
  --text: $grey-3;
  --accent: $accent;
}

$themeColours: (
  'white': $white,
  'black': $black,
  'grey-1': $grey-1,
  'grey-2': $grey-2,
  'grey-3': $grey-3,
  'grey-4': $grey-4,
  'grey-5': $grey-5,
  'grey-6': $grey-6,
  'grey-7': $grey-7,
  'grey-8': $grey-8,
  'accent': $accent,
  'accentl': $accent-l,
);

@each $themeColour, $i in $themeColours {
  .text_ {
    &#{$themeColour} {
      color: $i !important;
    }
  }
}

$transs: cubic-bezier(0, 0, 0.2, 1);

$icons: (
  'fb': -1px,
  'twitter': -52px,
  'insta': -105px,
  'linked': -156px,
);

@each $icon, $i in $icons {
  .icon_ {
    &#{$icon} {
      background: url(/images/icons/social-icons.svg) no-repeat;
      background-position-y: 0px;
      background-position-x: #{$i};
      width: 34px;
      height: 34px;
      display: inline-block;
    }
  }
}

@mixin str {
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 4;
  }
}

:export {
  primaryColor: $accent;
}
