@import '_media.scss';
@import '_variables.scss';
@import '_typo.scss';

.logo {
  color: 'red';
  fill: 'red';
}

/**      banner         **/
.banner_sec {
  padding-top: 100px;
  background: url(/images/banner-bg.svg);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 100%;
  min-height: 750px;
  @include tabv-m {
    padding-bottom: 0;
    height: 90vh;
    display: flex;
    align-items: flex-end;
  }

  @include tabv {
    background-position: center;
    background-size: cover;
    padding-top: 190px;
  }
  @include phone {
    padding-top: 140px;
  }
  .container_fluid {
    flex: 0 0 100%;
    height: 100%;
    .row {
      height: 100%;
    }
  }
  .banner_content {
    margin-bottom: 70px;
    @include phone {
      margin-bottom: 30px;
    }
    .main_title {
      margin-bottom: 24px;
      font-family: 'DM Serif Display';
      .rb_g {
        font-family: 'DM Serif Display';
      }
    }
    .h6 {
      color: $grey-3;
    }
  }
}
.left_blk,
.right_blk {
  flex: 0 0 24%;
  padding: 0px 15px;
  @include tabv {
    flex: 0 0 100%;
  }
}
.right_blk {
  @include tabv-m {
    position: relative;
    min-height: 300px;
    .lottie_player {
      position: absolute;
      top: 0;
      left: auto;
      right: 15px;
      width: 100%;
      height: 100%;
    }
  }
}
.left_blk {
  @include tabv {
    display: none;
  }
  .lottie_player {
    width: 100%;
    height: 368px;
  }
}
.center_blk {
  flex: 0 0 52%;
  max-width: 650px;
  margin-bottom: 120px;
  padding: 0px 15px;
  margin: auto;
  @include tabv-m {
    align-self: center;
  }
  @include tabv {
    flex: 0 0 100%;
    margin-bottom: 50px;
  }
}
.searchbar_container {
  // background: #ffffff;
  // border: 1px solid rgba(207, 200, 196, 0.3);
  // box-shadow: 0px 24px 68px rgba(208, 192, 182, 0.2);
  // border-radius: 38px;
  display: flex;
  align-items: center;
  padding: 9px;
  justify-content: space-between;
  margin-bottom: 24px;
  @include phone {
    padding: 6px;
  }
  .search_field {
    display: flex;
    align-items: center;
    width: 100%;
    .form {
      flex: 0 0 85%;
    }
    .search_icon {
      margin: 0px 14px;
      width: 20px;
      height: 20px;
      @include phone {
        margin: 0px 8px 0px 3px;
      }
    }
    .form_control {
      width: 100%;
      padding: 14px 0px;
      border: none;
      font-weight: 600;
      font-size: 18px;
      line-height: 1.44;
      letter-spacing: -0.3px;
      color: $grey-1;
      outline: none !important;
      box-shadow: none;
      @include phone {
        padding: 4px 0px;
        font-size: 14px;
      }
      &::placeholder {
        color: $grey-5;
      }
    }
  }
}
/**   trustpilot     **/
.trustpilot_sec {
  padding: 30px 0px;
  background: #fffcfa;
  @include phone {
    padding: 20px 0px;
  }
}
.review_slider_wrpr {
  margin-top: 60px;
  padding-bottom: 60px;
  @include phone {
    margin-top: 30px;
    padding-bottom: 30px;
  }
  .slick-track {
    display: flex;
  }
  .slick-slide {
    display: flex;
    align-self: stretch;
    height: unset;
    > div {
      display: flex;
      align-self: stretch;
      width: 100%;
    }
  }

  .review {
    background: #ffffff;
    border: 1px solid #faf3ef;
    border-radius: 16px;
    padding: 32px;
    height: inherit;
    margin: 10px 16px;
    transition: all 0.3s $transs;
    @include phone {
      margin: 10px 6px;
      padding: 15px;
    }
    &:hover {
      box-shadow: 0px 5px 10px 0px #3040540d;
      transition: all 0.3s $transs;
    }
    .rating_sec {
      justify-content: flex-start;
    }
    .review_cntnt {
      margin-top: 25px;
      .review_head {
        margin-bottom: 12px;
        font-weight: 600;
        font-size: 24px;
        line-height: 1.3;
        letter-spacing: -0.3px;
        color: $grey-2;

        @include phone {
          font-size: 18px;
        }
      }
      .review_desc {
        font-feature-settings: 'kern' off;
        color: $grey-3;
      }
    }
  }
  /**         uncomment this if slick arrow css not working (slick arrow css with parent class) start          **/

  // .cstm_slick_arrow {
  //   position: absolute;
  //   top: auto;
  //   bottom: -70px;
  //   left: -50px;
  //   right: 0;
  //   margin: 0 auto;
  //   max-width: fit-content;
  //   background: transparent;
  //   border: none;
  //   cursor: pointer;
  //   @include phone{
  //     bottom: -40px;
  //   }
  //   &.slick_next{
  //     transform: rotate(180deg);
  //     bottom: -67px;
  //     right: -70px;
  //     @include phone{
  //       bottom: -37px;
  //     }
  //   }
  // }
  /**         uncomment this if slick arrow css not working (slick arrow css with parent class) end          **/
}

/*      slick arrow css without parent class start    */

.cstm_slick_arrow {
  position: absolute;
  top: auto;
  bottom: -70px;
  left: -50px;
  right: 0;
  margin: 0 auto;
  max-width: fit-content;
  background: transparent;
  border: none;
  cursor: pointer;
  @include phone {
    bottom: -40px;
  }
  &.slick_next {
    transform: rotate(180deg);
    bottom: -67px;
    right: -70px;
    @include phone {
      bottom: -37px;
    }
  }
}

/*      slick arrow css without parent class end    */

/**   why choose us    **/
.wcu_sec {
  background-color: #112135;
  padding: 120px 0px;
  @include tab {
    padding: 80px 0px;
  }
  @include phone {
    padding: 50px 0px;
  }
  .wcu_point_row {
    margin-top: 70px;
    @include phone {
      margin-top: 10px;
    }
    .wcu_point {
      display: flex;
      align-items: center;
      @include phone {
        flex: 0 0 100%;
        margin: 15px;
      }
      .wcu_p_img {
        background: #faf3ef;
        border-radius: 50px;
        width: 74px;
        height: 74px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        // display: block;
      }
      .wcu_p_content {
        .point_title {
          margin-bottom: 6px;
          font-weight: 600;
        }
      }
    }
  }
}

/**        Service Section           **/

.service_sec {
  background: #fffcfa;
  padding: 120px 0px;
  position: relative;
  overflow: hidden;
  @include tab {
    padding: 80px 0px;
  }
  @include phone {
    padding: 50px 0px;
  }
  .heading_blk {
    margin-bottom: 20px;
  }
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(/images/service-bg.svg);
    background-position: -60% 70%;
    background-size: 90%;
    background-repeat: no-repeat;
    z-index: 0;
    opacity: 0.3;
  }
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(/images/service-bg.svg);
    background-position: 100% 30%;
    background-size: 100%;
    background-repeat: no-repeat;
    z-index: 0;
    opacity: 0.7;
    top: 0%;
    right: -40%;
  }
  .service_col {
    flex: 0 0 32%;
    z-index: 10;
    @include phone {
      flex: 0 0 100%;
    }
    .service_card {
      background: #ffffff;
      box-shadow: 0px 10px 29px rgba(143, 95, 67, 0.14);
      border-radius: 16px;
      overflow: hidden;
      margin-bottom: 32px;
      position: relative;
      @include phone {
        margin-bottom: 20px;
      }
      .service_img {
        overflow: hidden;
        position: relative;
        height: 240px;
        @include desk {
          height: 300px;
        }
        @include tab {
          height: 200px;
        }
        @include tabv {
          height: 200px;
        }
        @include phone {
          height: 300px;
        }
        @include phonev {
          height: 200px;
        }
        .img {
          transform: scale(1);
          transition: all 0.3s $transs;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      &:hover {
        .service_img {
          .img {
            transform: scale(1.1);
            transition: all 0.3s $transs;
          }
        }
      }
      .service_content {
        padding: 32px;
        padding-top: 42px;
        position: relative;
        @include tab {
          padding: 20px;
          padding-top: 35px;
        }

        .service_icon {
          background: #ffffff;
          box-shadow: 0px 10px 10px rgba(143, 95, 67, 0.06);
          border-radius: 50px;
          width: 60px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: -30px;
          left: 20px;
          z-index: 9;
        }
        .service_name {
          font-weight: 600;
          font-size: 24px;
          line-height: 1.33;
          letter-spacing: -0.3px;
          color: $grey-2;
          margin-bottom: 6px;
        }
        .service_desc {
          font-feature-settings: 'kern' off;
          color: $grey-3;
        }
      }
      .service_link {
        @include str;
      }
    }
  }
}

/**        faq section          **/
.faq_sec {
  background: #fff4f3;
  padding: 120px 0px 180px;
  text-align: center;
  position: relative;
  @include tab {
    padding: 80px 0px 150px;
  }
  @include phone {
    padding: 50px 0px 100px;
  }
  &:after {
    content: '';
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    width: 100%;
    height: 60px;
    border-radius: 88px 88px 0px 0px;
    @include phone {
      height: 30px;
    }
  }
}
.faq_card_wrpr {
  margin: 70px 0px;
  @include phone {
    margin: 20px 0px;
  }
  .faq_card {
    flex: 0 0 32%;
    padding: 32px;
    border-radius: 8px;
    border: 1px solid rgba(237, 205, 202, 0.3);
    margin: 15px 0px;
    text-align: left;
    @include tabv {
      flex: 0 0 49%;
      padding: 20px;
      margin: 10px 0px;
      // &:nth-last-child(1),
      // &:nth-last-child(2) {
      //   display: none;
      // }
    }
    @include phone {
      flex: 0 0 100%;
      padding: 20px;
      margin: 10px 0px;
    }
    .faq_title {
      margin-bottom: 12px;
      font-weight: 600;
      font-size: 24px;
      line-height: 1.33;
      letter-spacing: -0.3px;
      color: $grey-2;
    }
    .faq_desc {
      color: $grey-3;
      font-size: 16px;
    }
  }
}

/**        our story section          **/
.story_sec {
  padding: 60px 0px 0px;
  @include phone {
    padding: 40px 0px 0px;
  }

  .our_story_block {
    max-width: 75%;
    margin: 0 auto;
    @include phone {
      max-width: 100%;
    }
    .our_stody_edsc {
      font-size: 20px;
      font-weight: 400;
      letter-spacing: -0.3px;
      margin: 40px 0px 70px;
      line-height: 1.67;
      color: $grey-2;
      @include phone {
        margin: 30px 0px 50px;
      }
    }
    .our_story_img {
      margin-top: 60px;
    }
  }
}

.contact_us {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.3px;
  margin: 30px 0px;
  line-height: 1.67;
  color: $grey-2;
  @include phone {
    margin: 30px 0px 50px;
  }
}

/**        footer          **/
.footer {
  padding: 70px 0px;
  background: #112135;
  @include phone {
    padding: 50px 0px;
  }
  .logo_sec {
    border-bottom: 1px solid $grey-2;
    padding-bottom: 48px;
    @include phone {
      padding-bottom: 30px;
    }
  }
  .footer_lower {
    padding-top: 48px;
    @include phone {
      padding-top: 30px;
    }
    .addr {
      color: #bfc5cc;
      margin-bottom: 12px;
    }
    .contect_info {
      color: $white;
      .contact_link {
        font-weight: 700;
        margin: 0px 4px;
        text-decoration: none;
      }
    }
  }
}

.icon_fb {
  background: url(/images/icons/social-icons.svg) no-repeat;
  background-position-y: 0px;
  background-position-x: -1px;
  width: 34px;
  height: 34px;
  display: inline-block;
}

.icon_twitter {
  background-position-y: 0px;
  background-position-x: -52px;
  width: 34px;
  height: 34px;
  display: inline-block;
}

.icon_insta {
  background: url(/images/icons/social-icons.svg) no-repeat;
  background-position-y: 0px;
  background-position-x: -105px;
  width: 34px;
  height: 34px;
  display: inline-block;
}

.icon_linked {
  background: url(/images/icons/social-icons.svg) no-repeat;
  background-position-y: 0px;
  background-position-x: -156px;
  width: 34px;
  height: 34px;
  display: inline-block;
}

.f_select {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 32px;
    right: 26px;
    width: 16px;
    height: 16px;
    background: url(/images/icons/arrow-down.svg) no-repeat;
    background-size: contain;
    pointer-events: none;
    @include phone {
      top: 24px;
      width: 15px;
    }
  }
}
