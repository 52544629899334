$phonev: 480px;
$phone: 767px;
$tabv: 992px;
$tab: 1200px;
$desk: 1400px;


@mixin phonev {
  @media (max-width: #{$phonev}) {
    @content;
  }
}

@mixin phone {
  @media (max-width: #{$phone}) {
    @content;
  }
}

@mixin tabv {
  @media (max-width: #{$tabv}) {
    @content;
  }
}

@mixin tab {
  @media (max-width: #{$tab}) {
    @content;
  }
}

@mixin desk {
  @media (max-width: #{$desk}) {
    @content;
  }
}


@mixin phonev-m {
  @media (min-width: #{$phonev}) {
    @content;
  }
}

@mixin phone-m {
  @media (min-width: #{$phone}) {
    @content;
  }
}

@mixin tabv-m {
  @media (min-width: #{$tabv}) {
    @content;
  }
}

@mixin tab-m {
  @media (min-width: #{$tab}) {
    @content;
  }
}

@mixin desk-m {
  @media (min-width: #{$desk}) {
    @content;
  }
}

